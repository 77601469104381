export const PARTNER_ITEMS = [
  {
    company: 'amp.svg',
    dimensions: {
      width: 92,
      height: 40
    }
  },
  {
    company: 'anz.svg',
    dimensions: {
      width: 103,
      height: 32
    }
  },
  {
    company: 'bank-of-melbourne.svg',
    dimensions: {
      width: 92,
      height: 24
    }
  },
  {
    company: 'bank-sa.svg',
    dimensions: {
      width: 88,
      height: 40
    }
  },
  {
    company: 'common-wealth.svg',
    dimensions: {
      width: 87,
      height: 57
    }
  },
  {
    company: 'bank-west.svg',
    dimensions: {
      width: 111,
      height: 22
    }
  }
]

export const GREAT_DEAL_ITEMS = [
  {
    title: 'Tell us what<br /> you need',
    icon: `${APP_ASSET_BASEURL}edge/static/images/steps/tell-us.svg`,
    iconDimensions: {
      width: 60,
      height: 60
    }
  },
  {
    title: 'We’ll find a suitable<br /> home loan from our<br /> panel',
    icon: `${APP_ASSET_BASEURL}edge/static/images/steps/find-plan.svg`,
    iconDimensions: {
      width: 65,
      height: 60
    }
  },
  {
    title: 'We’ll help you<br /> through the approval<br /> process',
    icon: `${APP_ASSET_BASEURL}edge/static/images/steps/approval.svg`,
    iconDimensions: {
      width: 64,
      height: 64
    }
  },
  {
    title: 'Relax. You’ve now<br /> got a home loan<br /> at a great rate',
    icon: `${APP_ASSET_BASEURL}edge/static/images/steps/relax.svg`,
    iconDimensions: {
      width: 64,
      height: 64
    }
  }
]

export const REPAYMENTS = [
  {
    title: 'Compare 50+ lenders',
    description: 'Our lender panel is one of the biggest in Australia. We compare all the big banks you already know and some specialist lenders other brokers don’t offer. Whatever you need, we’re confident there’s a home loan that suits you.'
  },
  {
    title: 'Completely independent',
    description: 'We\'re mortgage brokers, and we’re not owned by a big bank or a home loan provider. That means we\'ve only got your best interests in mind. Our mission is to find you the best loan, not the mortgage that makes us the most money.'
  },
  {
    title: 'Expert support',
    description: 'Our specialists do the hard work for you. They\'ll speak to the lenders, help you out with the paperwork and be by your side until your loan is finalised. Traditional lenders can be slow and painful - everything we\'re not.'
  },
  {
    title: 'Seriously easy',
    description: 'You can find a range of loan options in a matter of minutes - all without leaving your sofa. No more time wasted speaking to banks who\'ll say no or realising that you don\'t meet a lender\'s criteria.'
  }
]

export const GUIDES_ITEMS = [
  {
    title: 'What does the RBA’s current cash rate mean for your home loan?',
    content: 'The Reserve Bank of Australia has kept the official case rate at 0.1% since late 2020 but banks are hiking rates. Here’s what you need to know.',
    imageUrl: `${APP_ASSET_BASEURL}edge/static/images/guides/home-loans/rba-cash-rate.png`,
    pageUrl: '/home-loans/rba-rate-update/',
    imageDimensions: {
      width: 291,
      height: 202
    }
  },
  {
    title: 'How to refinance your current home loan',
    content: 'Redraw facilities and offset accounts are two common features you’ll encounter in the mortgage hopping process. Both features can save you money, but only if you use them to their full potential.',
    imageUrl: `${APP_ASSET_BASEURL}edge/static/images/guides/home-loans/refinance.png`,
    pageUrl: '/home-loans/refinancing/',
    imageDimensions: {
      width: 291,
      height: 202
    }
  },
  {
    title: 'How to get faster pre-approval for your home loan',
    content: 'Found the home of your dreams, but don’t know how to move quickly? Here’s what you need to know about getting a faster home loan approval.',
    imageUrl: `${APP_ASSET_BASEURL}edge/static/images/guides/home-loans/pre-approval.png`,
    pageUrl: '/home-loans/fast-pre-approval/',
    imageDimensions: {
      width: 291,
      height: 202
    }
  }
]

export const FAQ_APPLY_ITEMS = [
  {
    title: '<span class="font-normal">Step 1:</span> Decide what to do with your new property',
    content: `
      <p class="mb-4 text-base md:text-lg">
        For example, are you buying a holiday home? An investment property? Or is it your main residence? If you’re buying a family home and intend to live there from the beginning, you can look for an owner occupier loan.
      </p>
      <p class="mb-4 text-base md:text-lg">
        Owner occupier loans typically attract lower interest rates than investment property loans. On the other hand, investment property loans usually include additional features and, of course, investment properties usually produce income.
      </p>
    `
  },
  {
    title: '<span class="font-normal">Step 2:</span> Compare home loan interest rates and features',
    content: `
      <p class="mb-4 text-base md:text-lg">
        When evaluating home loans, the initial focus may be on the advertised interest rates, but it's important to consider your mortgage comparison rate as well. This rate integrates your interest rate with various associated fees so you get the actual cost of the loan.
      </p>
      <p class="mb-4 text-base md:text-lg">
        You should also consider how much of a difference it makes having a fixed or variable interest rate on your home loan, or a combination of the two.
      </p>
      <p class="mb-4 text-base md:text-lg">
        <strong>Looking at the various home loan features offered by different lenders is also a good idea.</strong>
      </p>
      <p class="mb-4 text-base md:text-lg">
        Do you want or need the ability to make extra repayments on your loan without racking up extra fees?
      </p>
      <p class="mb-4 text-base md:text-lg">
        Would you like to be able to redraw these extra funds when you need to? Is an offset account better for you tax-wise? Other loan features to weigh up include:
      </p>
      <ul class="mb-4 list-disc pl-12 text-base md:text-lg">
        <li>Fixed vs Variable interest rates.</li>
        <li>Fees</li>
        <li>Repayment type (i.e. weekly, fortnightly, or monthly)</li>
      </ul>
      <p class="text-base md:text-lg">
        You can find a lot of this information on our home loan comparison website, as well as expert tips from out top mortgage brokers.
      </p>
    `
  },
  {
    title: '<span class="font-normal">Step 3:</span> Find out how much you can borrow (i.e. your borrowing capacity)',
    content: `
      <p class="mb-4 text-base md:text-lg">
        Working out how much you can borrow is part of your home loan 'pre-approval' or 'conditional approval' process. While a pre-approval doesn't guarantee loan approval, it gives you an estimated amount you can borrow from your chosen lender, which helps plan your home buying budget. You do not need a pre-approval, but it's useful to have one, especially if you're bidding at property auctions.
      </p>
      <p class="text-base md:text-lg">
        What you do need to know is your borrowing capacity. This is the amount a lender is prepared to lend you, and it’s the first step in your home loan journey. You can usually get a ballpark number from your lender by submitting the documents they ask for, such as:
      </p>
      <p class="text-base md:text-lg">
        payslips,<br>
        bank statements,<br>
        evidence of savings (to prove your deposit),<br>
        employment contracts,<br>
        other loan statements (e.g. any buy now pay later arrangements),<br>
        Centrelink statements, etc.
      </p>
      <p class="mb-4 text-base md:text-lg">
        BAS statements or tax returns if you're self employed, or want your side-hustle income to count towards your borrowing capacity.
      </p>
      <p class="mb-4 text-base md:text-lg">
        You'll also need to provide a list of your current assets (such as your car or investments) and any liabilities (like your credit card or any personal loan debt), along with multiple forms of identification (e.g., driver’s license, passport, birth certificate, and Medicare card).
      </p>
      <p class="text-base md:text-lg">
        Different lenders will have different paperwork requirements, and you'll find you'll be eligible for different borrowing capacities each time. For an initial estimate of your borrowing capacity use our free home loan borrowing calculator.
      </p>
    `
  },
  {
    title: '<span class="font-normal">Step 4:</span> Preparing your home loan application',
    content: `
      <p class="text-base md:text-lg">
        After selecting a lender and a home loan, it's time to delve into the practicalities of applying for a mortgage. Whether you’re proceeding with pre-approval or moving straight into applying for your actual loan, the application process is similar:<br><br> A thorough assessment by your lender of your current financial situation.<br>Official submission of your home loan application to your lender.<br><br> Your lender conducts a valuation of the property you intend to purchase.<br>Your lender either approves your loan application, and presents you with an offer or rejects your application.<br><br> Assuming your loan is approved, your conveyancer or solicitor coordinates the necessary legal checks to transfer the property title from the seller to you. They also liaise with your lender regarding the disbursement of funds.<br><br> Your loan is settled, and the funds are disbursed for your home purchase (this is the bit where you get your keys).<br>You either move in, rent it out, holiday there, or proceed as planned with your new property.
      </p>`
  }
]

export const FEATURE_ITEMS = [
  {
    title: 'Free extra repayments',
    description: 'Contributing extra funds to your loan helps pay down your debt faster, saving you interest over the long term. Most variable rate loans permit extra repayments, while certain fixed-rate loans may offer this feature with capped annual limits.',
    icon: `${APP_ASSET_BASEURL}edge/static/images/products/home-loans/extra.svg`,
    iconDimensions: {
      width: 60,
      height: 60
    }
  },
  {
    title: 'Redraw facility',
    description: ' If your loan allows extra repayments, a redraw facility is typically available so you can retrieve those additional funds when needed. There may be fees attached to access these funds.',
    icon: `${APP_ASSET_BASEURL}edge/static/images/products/home-loans/redraw.svg`,
    iconDimensions: {
      width: 65,
      height: 60
    }
  },
  {
    title: 'Offset account',
    description: 'Operating similarly to a standard bank account, an offset account offsets every dollar in the account against your loan amount.',
    icon: `${APP_ASSET_BASEURL}edge/static/images/products/home-loans/offset.svg`,
    iconDimensions: {
      width: 64,
      height: 64
    }
  },
  {
    title: 'Split rate option',
    description: 'For those torn between the stability of a fixed rate and the flexibility of a variable rate, a split loan allows you to divide your loan into two accounts, one with a fixed rate and the other with a variable rate.',
    icon: `${APP_ASSET_BASEURL}edge/static/images/products/home-loans/split.svg`,
    iconDimensions: {
      width: 64,
      height: 64
    }
  }
]

export const CALCULATOR_ITEMS = [
  {
    title: 'Refinance Calculator',
    description: 'Get an estimate of how much your home loan repayments might be. <br class="hidden md:block"><br class="hidden md:block">',
    url: '/home-loans/calculators/refinance/',
    icon: `${APP_ASSET_BASEURL}edge/static/images/products/home-loans/calculators/repayment-calculator.svg`,
    iconDimensions: {
      width: 64,
      height: 64
    }
  },
  {
    title: 'Stamp Duty',
    description: 'Get an estimate of the costs of buying a property, including stamp duty and other fees.',
    url: '/home-loans/calculators/stamp-duty/',
    icon: `${APP_ASSET_BASEURL}edge/static/images/products/home-loans/calculators/stamp-duty.svg`,
    iconDimensions: {
      width: 64,
      height: 64
    }
  },
  {
    title: 'Deposit Calculator',
    description: 'Get an estimate of just how much you have left for a deposit after initial fees are taken out.',
    url: '/home-loans/calculators/deposit/',
    icon: `${APP_ASSET_BASEURL}edge/static/images/products/home-loans/calculators/deposit-calculator.svg`,
    iconDimensions: {
      width: 64,
      height: 64
    }
  }
]

export const SITE_LINKS = [
  {
    title: 'Home loans For seniors',
    url: '/home-loans/home-loans-for-seniors/',
    desc: 'There’s no age that’s considered ‘too old’ for a home loan, but you may face tougher lending criteria.'
  },
  {
    title: 'Refinancing',
    url: '/home-loans/refinancing/',
    desc: 'You don’t have to stay with your current lender when refinancing – in fact, a competitor may offer you a much better rate.'
  },
  {
    title: 'Fixed rate home loans',
    url: '/home-loans/fixed-rate/',
    desc: 'Fixed rate mortgages are a little safer than other options. While you may pay a slightly higher interest rate when the official cash rate is low, you are protected from interest rate increases during the fixed rate period of your loan.'
  },
  {
    title: 'First home loans',
    url: '/home-loans/everything-to-know-buying-first-home/',
    desc: 'Are you on the market for your first home? The whole process can seem a bit daunting at first.'
  }
]

export const GLOSSARY_ITEMS = [
  {
    title: 'A - E',
    content: `
      <p class="mb-4">
        <strong>Amortisation:</strong> Amortisation refers to the gradual repayment of a loan over time through regular payments, which typically include both principal and interest. With each payment made, a portion goes towards reducing the loan's principal balance, while the remainder covers the accrued interest charges. As the loan term progresses, the proportion of each payment allocated to principal repayment increases, gradually reducing the outstanding balance. This process continues until the loan is fully repaid at the end of the loan term. Amortisation schedules outline the specific breakdown of principal and interest payments over the life of the loan, allowing borrowers to track their progress in repaying the debt.
      </p>
      <p class="mb-4">
        <strong>Bank Valuation:</strong> A bank valuation, also known as a lender's valuation, is an assessment of the estimated market value of a property conducted by a professional valuer appointed by the lender. The bank valuation is used by the lender to determine the property's worth for the purpose of assessing the loan-to-value ratio (LVR) and determining the maximum loan amount they are willing to lend to a borrower. The bank valuation helps the lender evaluate the level of risk associated with the property as collateral for the loan. It's important to note that the bank valuation may differ from the purchase price or an independent valuation conducted by the borrower. Borrowers should be aware that the bank valuation can impact the loan amount they are eligible for and potentially affect the loan terms and conditions.
      </p>
      <p class="mb-4">
        <strong>Borrower:</strong> The individual or entity that receives the home loan and is responsible for repaying it.
      </p>
      <p class="mb-4">
        <strong>Break Fee:</strong> Also known as an Early Repayment Fee or Early Termination Fee, a break fee is a penalty charged by the lender when a borrower pays off or refinances their home loan before the agreed-upon term. This fee is designed to compensate the lender for the financial loss incurred due to the early repayment or termination of the loan. The break fee amount can vary and is typically calculated based on factors such as the remaining loan balance, the interest rate differential, and the remaining term of the loan. It is important for borrowers to carefully review the terms and conditions of their loan agreement to understand any potential break fees before considering early repayment or refinancing options.
      </p>
      <p class="mb-4">
        <strong>Bridging loan:</strong> Short term loan that gives you six months to sell your existing property before moving into your new home.
      </p>
      <p class="mb-4">
        <strong>Buffer Rate:</strong> A buffer rate, also known as a serviceability buffer, is an additional percentage applied by lenders to the interest rate when assessing a borrower's loan application. The buffer rate serves as a safety measure to account for potential interest rate increases or changes in the borrower's financial circumstances. It provides a cushion to ensure that borrowers can comfortably afford their loan repayments, even if interest rates rise in the future. The buffer rate is typically added on top of the actual interest rate to determine the borrower's ability to service the loan. The specific buffer rate can vary between lenders but is commonly set at around 2-3%. The buffer rate is intended to mitigate the risk of borrowers facing financial stress if interest rates were to increase. Borrowers should be aware of the buffer rate requirements when assessing their borrowing capacity and loan affordability.
      </p>
      <p class="mb-4">
        <strong>Comparison Interest Rate:</strong> The calculated rate that helps borrowers compare the true cost of a loan, including both the interest rate and any upfront or ongoing fees. The comparison interest rate is expressed as an annual percentage rate (APR) and allows borrowers to compare different loan products on a more equal basis by considering all associated costs.
      </p>
      <p class="mb-4">
        <strong>Construction loan:</strong> Loan that lets you pay off construction costs as funds are released in stages instead of all at once.
      </p>
      <p class="mb-4">
        <strong>Equity:</strong> The difference between the appraised value of the property and the amount owed on the mortgage.
      </p>
      <p class="mb-4">
        <strong>Equity Loan:</strong> An equity loan, also known as a home equity loan or a second mortgage, is a loan that allows homeowners to borrow against the equity they have built up in their property. The loan amount is determined by the difference between the property's current value and the amount still owed on the primary mortgage. Homeowners can use the funds from an equity loan for various purposes, such as home renovations, debt consolidation, or other financial needs. The loan is secured by the property, and interest is charged on the borrowed amount. It's important for borrowers to consider their financial circumstances and repayment ability before taking out an equity loan.
      </p>
      <p class="mb-4">
        <strong>Equity Release:</strong> Equity release refers to a financial product that allows homeowners, typically those who are retired or approaching retirement age, to access the equity tied up in their property. It enables homeowners to convert a portion of their home's value into cash while retaining the right to live in the property. Equity release options include lifetime mortgages and home reversion plans. However, it's important for homeowners to carefully consider the potential long-term impacts and seek independent financial advice before proceeding with an equity release arrangement.
      </p>
    `
  },
  {
    title: 'F - L',
    content: `
      <p class="mb-4">
        <strong>Fixed-Rate Loan:</strong> A home loan with an interest rate that remains unchanged for a specific period, providing predictable repayments.
      </p>
      <p class="mb-4">
        <strong>Home Loan:</strong> A loan provided by a financial institution to finance the purchase of a residential property.
      </p>
      <p class="mb-4">
        <strong>Honeymoon Rate:</strong> A promotional interest rate offered by some lenders as an incentive to attract borrowers into an introductory loan. It is typically a discounted interest rate that is lower than the standard variable rate for an initial period, usually six to twelve months. After the honeymoon period ends, the interest rate usually reverts to the lender's standard variable rate. Borrowers should carefully consider the long-term affordability of the loan beyond the honeymoon period when evaluating this type of rate.
      </p>
      <p class="mb-4">
        <strong>Interest:</strong> The cost charged by the lender for borrowing the money, usually expressed as an annual percentage rate (APR).
      </p>
      <p class="mb-4">
        <strong>Interest Only Loan:</strong> An interest only loan is a type of loan where the borrower is only required to pay the interest charges on the loan for a specified period, typically ranging from one to ten years. During this period, the borrower is not required to make principal repayments. As a result, the monthly repayments are lower compared to a loan with principal and interest repayments. However, it's important to note that once the interest-only period ends, the borrower will typically transition to making principal and interest repayments, resulting in higher monthly repayments. Interest-only loans are often used by investors to manage cash flow and maximize tax benefits, but they can also be used by owner-occupiers. Borrowers considering an interest only loan should carefully assess their financial circumstances and understand the long-term implications of this type of loan structure.
      </p>
      <p class="mb-4">
        <strong>Investment home loan:</strong> Allows you to purchase a property as an investment and use the rental income to pay off your mortgage repayments.
      </p>
      <p class="mb-4">
        <strong>Lender:</strong> The financial institution or bank that provides the home loan to the borrower.
      </p>
      <p class="mb-4">
        <strong>Line of Credit:</strong> A line of credit, also known as a home equity line of credit (HELOC), is a flexible borrowing option that allows homeowners to access funds up to a predetermined credit limit. It is secured against the equity in the property. Similar to a credit card, borrowers can withdraw funds from the line of credit as needed and only pay interest on the amount borrowed. As borrowers repay the borrowed amount, the credit becomes available again, providing ongoing access to funds. A line of credit can be used for various purposes, such as home improvements, debt consolidation, or other expenses. It's important to note that interest rates and terms may vary, and borrowers should carefully consider the repayment requirements and potential risks before utilizing a line of credit.
      </p>
      <p class="mb-4">
        <strong>LMI (Lenders Mortgage Insurance):</strong> Insurance coverage taken out by the lender to protect themselves against default risk when the borrower has a high LVR.
      </p>
      <p class="mb-4">
        <strong>Loan Term:</strong> The agreed-upon period in which the borrower must repay the loan in full.
      </p>
      <p class="mb-4">
        <strong>Low-doc loan:</strong> A loan for borrowers who have trouble providing paperwork needed for a traditional home loan, such as investors and the self-employed.
      </p>
      <p class="mb-4">
        <strong>LVR (Loan-to-Value Ratio):</strong> The ratio of the loan amount to the appraised value of the property, expressed as a percentage.
      </p>
      <p class="mb-4">
        <strong>Mortgage:</strong> A legal agreement that gives the lender a security interest in the property as collateral for the loan.
      </p>
    `
  },
  {
    title: 'M - V',
    content: `
      <p class="mb-4">
        <strong>Mortgage Protection Insurance:</strong> Mortgage protection insurance, also known as mortgage insurance or loan protection insurance, is a type of insurance coverage designed to provide financial protection to borrowers in the event of unforeseen circumstances that may impact their ability to meet their mortgage repayments. It typically covers events such as disability, serious illness, involuntary unemployment, or death of the borrower. Mortgage protection insurance can help cover mortgage repayments for a specified period or pay off the outstanding loan balance entirely in the event of a covered claim. The terms and coverage of mortgage protection insurance can vary between insurance providers, so borrowers should carefully review the policy details, exclusions, and premiums before deciding to obtain this type of insurance. It's important to note that mortgage protection insurance is different from lenders mortgage insurance (LMI), which is insurance taken out by the lender to protect themselves against default risk for high loan-to-value ratio (LVR) loans.
      </p>
      <p class="mb-4">
        <strong>Non-conforming loan:</strong> Secures a loan by using other means, such as a larger deposit, but may have higher interest rates.
      </p>
      <p class="mb-4">
        <strong>Offset Facility/Account:</strong> An offset facility or account is a transaction account linked to a home loan. The balance in this account is offset against the outstanding loan balance, reducing the amount on which interest is calculated. For example, if a borrower has a home loan of $300,000 and an offset account with a balance of $50,000, they will only pay interest on the net amount of $250,000 ($300,000 - $50,000). By reducing the interest payable, an offset account can potentially save borrowers money and help repay the loan faster. The funds in the offset account remain accessible for everyday banking needs, and no interest is earned on the offset amount. It's important to check with the lender about any specific terms and conditions, as well as any fees or restrictions associated with an offset facility or account.
      </p>
      <p class="mb-4">
        <strong>Partial Offset:</strong> A partial offset is a variation of an offset facility that allows borrowers to link a transaction account to their home loan to partially offset the outstanding loan balance. The funds held in the linked account are only partially offset against the loan balance, reducing the interest payable on the remaining loan amount. For example, if a borrower has a home loan of $400,000 and a partial offset account with a balance of $20,000, the interest will be calculated based on the net amount of $380,000 ($400,000 - $20,000). The partial offset arrangement provides a benefit by reducing the interest payable but to a lesser extent than a full offset account. It's important to review the terms and conditions specific to the partial offset arrangement offered by the lender, including any fees or restrictions associated with it.
      </p>
      <p class="mb-4">
        <strong>Pre-approval:</strong> An initial assessment by the lender indicating the amount the borrower may be eligible to borrow, subject to final approval.
      </p>
      <p class="mb-4">
        <strong>Principal:</strong> The original amount of money borrowed from the lender, excluding interest.
      </p>
      <p class="mb-4">
        <strong>Rate Lock:</strong> A rate lock is an agreement between the borrower and the lender that guarantees a specific interest rate for a specified period. It is often used when there is an expectation of interest rate fluctuations in the market. By locking in a rate, the borrower ensures that their interest rate will remain unchanged during the lock-in period, regardless of any market fluctuations. This provides stability and certainty for borrowers during the home loan application process. However, rate lock agreements may come with certain conditions and fees, so it's important for borrowers to fully understand the terms and implications before entering into a rate lock arrangement.
      </p>
      <p class="mb-4">
        <strong>Redraw Facility/Account:</strong> A redraw facility or account is a feature offered by some lenders that allows borrowers to access any additional repayments they have made on their home loan. It provides flexibility by enabling borrowers to withdraw funds from their loan beyond the required repayment amount. For example, if a borrower has made extra repayments on their home loan, they can access those funds through the redraw facility. This can be useful for unexpected expenses or to make additional investments. It's important to note that there may be restrictions, fees, or minimum redraw amounts associated with this facility. Borrowers should review the terms and conditions of their loan agreement to understand how the redraw facility works and any limitations that may apply.
      </p>
      <p class="mb-4">
        <strong>Repayment:</strong> The regular installment paid by the borrower to the lender, which includes both principal and interest.
      </p>
      <p class="mb-4">
        <strong>Reverse mortgages:</strong> Ideal for people who have paid off or nearly paid off their mortgage as reverse mortgages involves using the equity on your current home as security for a new loan.
      </p>
      <p class="mb-4">
        <strong>Settlement:</strong> The final stage of a property purchase where legal ownership is transferred to the buyer and funds are exchanged.
      </p>
      <p class="mb-4">
        <strong>Split rate (principal and interest) loan:</strong> Allows you to divide between fixed and variable rates, meaning you can enjoy fixed repayments and make some extra repayments if needed. If you're ready to buy a home and understand the jargon, our Home Loan specialists can help find a loan for you across the 40+ lenders we compare.
      </p>
      <p class="mb-4">
        <strong>Stamp Duty:</strong> A government tax imposed on property transactions, including the purchase of a home.
      </p>
      <p class="mb-4">
        <strong>Variable-Rate Loan:</strong> A home loan with an interest rate that fluctuates based on market conditions, potentially resulting in changing repayments.
      </p>
    `
  }
]

export const FAQ_ITEMS = [
  {
    title: 'How are monthly home loan repayments calculated?',
    content: `
      <p class="mb-4 text-base md:text-lg">
        There are two different types of home loan repayments: interest-only and principal and interest. Which one you choose will make a difference to your monthly repayments.
      </p>
      <p class="mb-4 text-base md:text-lg">
        Interest-only loans: An interest-only home loan is just what it sounds like - your monthly repayments will only be paying off the interest you owe, and not chipping away at your principal loan amount. While this means your monthly repayments will be lower, keep in mind you’ll also wind up with the lump loan amount to pay off at the end. The other thing to remember is that usually, an interest-only term lasts for up to 5 years - after that, your lender may let you roll over into another interest-only term, or you might have to start making principal and interest repayments.
      </p>
      <p class="text-base md:text-lg">
        Principal and interest loans: This is what’s called an amortising loan, which means your bank has done the math so that if you pay the same amount each month of your loan, by the end of the loan term, you’ll have paid off all the interest, along with the initial loan principal. This means that your monthly repayments will be a bit higher than with an interest-only loan, but the good news is you won’t have a lump sum to pay off at the end.
      </p>
    `
  },
  {
    title: 'What does ‘comparison rate’ mean when I’m looking at interest rates?',
    content: `
      <p class="mb-4 text-base md:text-lg">
        Your mortgage comparison rate is shown next to the interest rate and is designed to help you get an accurate idea of the ‘true’ cost of your home loan. It takes into account both interest costs, and all the fees and charges that apply to your particular loan.
      </p>
      <p class="mb-4 text-base md:text-lg">
        There are a lot of different home loans options out there, but when you compare them based on your mortgage comparison rate, you know you’re looking at different options on an equal footing.
      </p>
      <p class="mb-4 text-base md:text-lg">
        One thing to remember is that the comparison rate can’t reflect things like offset accounts or other features that might save you money, so while it’s an important part of home loan decision making process, it’s not the only thing you need to consider.
      </p>
      <p class="text-base md:text-lg">
        You should also keep in mind that the advertised mortgage comparison rates from lenders are based on one specific loan example. You’ll likely have a different loan amount or loan term, so your personal comparison rate may be considerably different to what’s shown here.
      </p>
    `
  },
  {
    title: 'What is a construction loan?',
    content: `
      <p class="text-base md:text-lg">
        A construction loan caters to those who want to build their home, rather than buy an existing one. Unlike a traditional home loan, in which a lender gives you a lump sum at the outset, a construction loan is paid out in instalments throughout the construction process.
      </p>
      <p class="text-base md:text-lg">
        These funds go directly to the builder as the home is being built. In the meantime, you will pay only interest on the amount that has been drawn down. When the work is complete, your loan will typically switch from interest only repayments to principal and interest repayments.
      </p>
    `
  },
  {
    title: 'What’s the difference between fixed and variable home loan interest rates?',
    content: `
      <p class="mb-4 text-base md:text-lg">
        Fixed-rate home loans mean that the interest rate remains the same for the entire loan. The advantage of a fixed-rate loan is that you can be 100% certain of what your interest repayments will be. Having a fixed-rate loan also means you’re protected if interest rates rise.
      </p>
      <p class="mb-4 text-base md:text-lg">
        The interest rate on variable-rate home loans will change whenever the bank chooses by however much the bank chooses to change the rates to.
      </p>
      <p class="text-base md:text-lg">
        There are pros and cons to fixed and variable rate mortgages. There are some things you can consider before deciding which financial product is right for you, including:
      </p>
      <p class="text-base md:text-lg">
        Are additional repayments important to you? Some fixed rate lenders put limits on extra repayments, whereas variable loans have no limit.
      </p>
      <p class="text-base md:text-lg">
        Are you planning to close your mortgage in the near future?
      </p>
      <p class="mb-4 text-base md:text-lg">
        Do you prefer the certainty of a monthly set repayment amount? Fixed home loans can give you the certainty you need.
      </p>
      <p class="text-base md:text-lg">
        Our experts are here to help you navigate through fixed term and variable rate home loans. Compare today and find the loan that works for you.
      </p>
    `
  },
  {
    title: 'What is lenders mortgage insurance?',
    content: `
      <p class="mb-4 text-base md:text-lg">
        Lenders mortgage insurance (LMI) is designed to protect the lender when the borrower has a low deposit and the lender views the loan as a higher risk in case of default. 
      </p>
      <p class="text-base md:text-lg">
        LMI is typically a one-off payment you can expect to be charged if you borrow more than 80% of the home’s value.
      </p>
    `
  },
  {
    title: 'What are principal and interest loan repayments?',
    content: `
      <p class="mb-4 text-base md:text-lg">
        Principal and interest loans will have monthly repayments covering a small amount of the main amount you borrowed and the interest you owe the lender.
      </p>
      <p class="mb-4 text-base md:text-lg">
        Some home loans, such as investment loans, may charge interest-only repayments for a certain period before principal and interest payments begin.
      </p>
      <p class="mb-4 text-base md:text-lg">
        On top of regular home loan repayments, some loans give you the option to make extra repayments. Making extra repayments with any spare money you have helps reduce the total amount you owe, which also lowers your next interest payment.
      </p>
      <p class="text-base md:text-lg">
        Consistently making extra repayments can significantly reduce the cost of your loan.
      </p>
    `
  },
  {
    title: 'How do I calculate my principal and interest home loan repayments?',
    content: `
      <p class="mb-4 text-base md:text-lg">
        You can access several free online home loan calculators that will give you an estimate of your principal and interest repayments. All you need to do is input some basic details like the principal amount you want to borrow and the interest rate.
      </p>
      <p class="mb-4 text-base md:text-lg">
        Compare Club’s experts go above and beyond a calculator. Your specialist will let you know your monthly loan repayment for each option.
      </p>
      <p class="text-base md:text-lg">
        Rather than using a calculator to estimate your repayments on a theoretical home loan, Compare Club can give you real numbers for home loan products you can actually use. Get started today.
      </p>
    `
  },
  {
    title: 'What is an offset account?',
    content: `
      <p class="mb-4 text-base md:text-lg">
        An offset account is a standard bank account linked to your home loan. The money you deposit in the account is offset against what you still owe.
      </p>
      <p class="mb-4 text-base md:text-lg">
        The more money you have in your offset account, the less total interest you will need to pay. For example, if you have a home loan of $500,000 linked to an offset account with $50,000 in it, you would only be charged interest on $450,000.
      </p>
      <p class="text-base md:text-lg">
        Home loans that offer offset accounts have various settings that change how they function and how much you can save. When you compare home loans, it’s a good idea to investigate the details of a home loan with an offset account, so you know if it will be worthwhile for you.
      </p>
    `
  }
]

export const AUTHOR_ITEM = {
  name: 'Anthony Stevenson',
  description: 'Anton Stevenson, is the head of home loans at Compare Club. With over a decade of experience under his belt, Anton is dedicated to helping individuals make informed decisions when choosing a home loan. Whether it\'s finding the best deals on  your home loan or refinancing, Anton has a wealth of knowledge in the space.',
  image: '/images/authors/anthony-stevenson.svg',
  vertical: 'home loans',
  tips: [
    {
      value: 'Getting value for money out of your extras is key. If you claim regularly on benefits such as routine check-ups or a yearly pair of glasses, it could be worthwhile. If it’s more of a ‘just in case’, it may be best to save your money and pay for services as you go.'
    },
    {
      value: 'While having lower premiums is tempting, cheaper doesn’t necessarily mean better. Instead of sacrificing inclusions on your cover, consider increasing your excess to lower costs.'
    },
    {
      value: 'Flexibility is one of the key benefits of private health insurance. For example, any waiting periods you have already served will be recognised by your new fund if you switch policies. Just keep in mind that you’ll need to wait for any new or upgraded services and benefits.'
    }
  ]
}

export const INFO_TABLE = [
  {
    title: 'Private Health Cover',
    list: [
      'Covers Medicare recognised elective surgery procedures and treatments',
      'Subsidised access to private hospital rooms',
      'Reduced wait times on elective surgeries',
      'Choose preferred doctor or surgeon',
      'Rebates for non-Medicare funded care<br/> (e.g. ambulance, dental, optical, physio)',
      'Exemption from MLS*',
      'Exemption from LHC if appropriate health cover taken out at 30 years old'
    ]
  },
  {
    title: '<strong>When you compare home loans you have a better chance of finding one that has:</strong>',
    listIcon: {
      component: `${APP_ASSET_BASEURL}edge/static/images/products/home-loans/calculators/deposit-calculator.svg`
    },
    list: [
      'Does not cover non-essential surgeries or treatments',
      'Access to a public hospital bed on a ward',
      'Often longer wait times for non–emergency surgeries in public hospitals',
      'Medical practitioner chosen for you',
      'Non-Medicare funded care paid for in full by patient',
      'Must pay the MLS if income is above threshold',
      'LHC must be paid at 2% for each year private health cover wasn’t in place since age 30**'
    ]
  }
]
